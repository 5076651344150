<template>
    <div v-if="typeof post.content === 'string'" class="gap-4 overflow-hidden bg-white shadow rounded-xl">
        <div class="flex items-center justify-between p-4 cursor-pointer ">
            <div class="flex items-center gap-4">
                <Link :href="route('candidate.profile', post?.user?.id)">
                <Avatar size="10" :user="post.user" :postOrganization="post?.organization" class="bg-200-blue" />
                </Link>
                <div class="grid gap-1">
                    <h2 class="text-lg font-semibold text-primary">
                        {{ displayName }}
                    </h2>
                    <p class="text-xs text-disabled">{{ formattedDate(post.created_at) }}</p>
                </div>
            </div>

            <div class="relative"
                v-if="($page?.props?.auth?.user && $page?.props?.auth?.user?.id === post?.user_id) || superUser">
                <tippy>
                    <Dropdown align="right" width="48">
                        <template #trigger>
                            <GenericButton type="button" theme="inherit-text" class="flex">
                                <div class="cursor-pointer">
                                    <Icon type="3dots" />
                                </div>
                            </GenericButton>
                        </template>
                        <template #content>
                            <div @click="editPost()"
                                class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out cursor-pointer text-start hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                {{
                                    $t("Edit")
                                }}</div>
                            <div @click="confirmDelete(post.id)"
                                class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out cursor-pointer text-start hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                {{
                                    $t("Delete Post ")
                                }}</div>

                            <div v-if="post?.media[0]?.type === 'video'" @click="confirmDeleteVideo(post.id)"
                                class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out cursor-pointer text-start hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                {{
                                    $t("Delete Video")
                                }}</div>
                        </template>
                    </Dropdown>
                    <template #content>
                        {{ $t('More') }}
                    </template>
                </tippy>
            </div>
        </div>
        <div class="flex flex-col gap-3 text-sm whitespace-break-spaces text-primary">
            <template v-for="(part, index) in parsedContent" :key="index">
                <template v-if="part.isLink">
                    <LinkPreview class="inline-flex" :src="part.text" />
                </template>
                <template v-else>
                    <div class="px-4 py-2" v-if="part.text !== 'empty'">{{ part.text }}</div>
                </template>
            </template>
        </div>

        <div v-if="post.media && post.media.length > 0" :class="{ 'p-2': post.media.length > 1 }">
            <div class="lg:hidden">
                <div v-if="post.media && post.media.length == 1">
                    <div class="flex justify-center space-x-2">
                        <div v-for="(media, index) in post?.media" :key="index" class="flex-shrink-0 w-40 h-40">
                            <component :is="mediaType(media)" :src="mediaTypeUrl(media)" alt="File Media"
                                       loading="lazy"
                                class="object-cover w-full h-full text-center rounded-lg" />
                        </div>
                    </div>
                </div>
                <div v-else class="flex flex-col items-center space-y-1">
                    <carousel :items-to-show="1" class="my-4 overflow-hidden rounded-lg lg:rounded-none" @slide-start="handleSlideStart">
                        <slide v-for="(media, index) in post?.media" :key="index">
                            <component :is="mediaType(media)" :src="mediaTypeUrl(media)" alt="File Media"
                               :loading="index === 0 ? undefined : 'lazy'"
                                class="object-cover w-full h-full lg:rounded-lg" />
                        </slide>
                        <template #addons>
                            <navigation />
                            <pagination />
                        </template>
                    </carousel>
                    <!-- <div class="mt-2 text-center">
                        {{ currentSlide + 1 }}/{{ totalSlides }}
                    </div> -->
                </div>

            </div>
            <div class="hidden gap-2 overflow-x-auto rounded-lg lg:flex lg:gap-2 lg:overflow-x-auto lg:rounded-lg">
                <div class="relative w-full overflow-hidden shrink-0 lg:flex-1 aspect-video">
                    <div class="w-full h-full overflow-hidden" :class="{ 'rounded-lg': post?.media.length > 1 }">
                        <component :is="mediaType(selectedMedia ?? post?.media[0])"
                            :src="mediaTypeUrl(selectedMedia ?? post?.media[0])" alt="Media" loading="lazy"
                            class="object-cover object-center w-full h-full transition-transform transform" />
                    </div>
                </div>
                <div v-if="post?.media.length > 1" class="flex w-full h-full gap-2 shrink-0 lg:flex-col lg:w-1/6">
                    <div v-for="(media, index) in post?.media" :key="index"
                        class="z-10 w-full overflow-hidden rounded-lg cursor-pointer shrink-0"
                        @click="handleMediaClick(media)">
                        <component :is="mediaType(media)" :src="mediaTypeUrl(media, true)" alt="File Media"
                                  loading="lazy"
                            class="object-cover object-center w-full h-full transition-transform transform pointer-events-none hover:scale-105" />
                    </div>
                </div>
            </div>
        </div>

        <div class=""></div>
        <div class="flex items-center justify-between px-4">
            <PostLike :post="post" :postId="post.id" :initialLikeCounts="post.likeCount"
                :initialLikedUsers="post.likedUsers" initialUserLikedType="love" />
        </div>
		  <div class="flex items-center gap-5  pr-4 py-2 pl-4 border-t border-[#919EAB52]">
                <tippy class="flex-auto block w-full py-3">
                    <div @click="showComments = !showComments" class="flex items-center justify-center gap-2 text-sm font-bold cursor-pointer">
                        <Icon type="comment" color="#fff" />
								<span>    {{ $t('Comments') }}</span>
                    </div>
                    <template #content>
                        {{ $t('Comment') }}
                    </template>
                </tippy>
                <div @click="shareThisPost(post?.id)"
                    class="flex-auto w-full py-3 text-sm cursor-pointer">
                    <tippy class="flex items-center justify-center gap-2 font-bold">
                        <Icon type="share" />
								<span class="">    {{ $t('Share') }}</span>
                        <template #content>
                            {{ $t('Share') }}
                        </template>
                    </tippy>
                </div>
            </div>
        <div v-show="showComments" class="grid gap-3 px-4">

            <Comment v-for="comment in post.comments" :key="comment.id" :comment="comment" :user="post.user" />

            <div class="flex gap-4">
                <Avatar size="10" :user="comment" />
                <div class="relative flex-auto">
                    <input type="text" placeholder="Write a comment…"
                        class="px-4 py-2 border w-full border-[#919EAB33] text-base pr-16 rounded-lg text-primary placeholder:text-disabled"
                        v-model="selectedEmoji" @keyup.enter="submitComment">
                    <div class="absolute flex items-center gap-3 -translate-y-1/2 right-4 top-1/2">
                        <EmojiButton @emoji-selected="handleEmojiSelected" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="grid gap-3 p-4 bg-white shadow-card rounded-xl">
        <div class="flex flex-col items-center justify-between gap-5 xl:flex-row">
            <div v-if="post.content?.media" class="h-full">
                <component
                    :is="mediaType(post.content?.media)"
                    :src="path + mediaTypeUrl(post.content?.media)"
                    alt="Media"
                    class="object-cover object-center w-full h-full rounded-lg sm:max-w-52 sm:max-h-40 aspect-video transition-transform transform hover:scale-105"
                />
            </div>

            <div class="flex justify-between w-full">
                <div class="w-full">
                    <div class="flex flex-col gap-2">
                        <Link as="h2" :href="route('events.show', post?.id)" class="text-lg font-semibold transition-all cursor-pointer text-primary hover:text-main" v-text="post?.content?.title || '-'"></Link>
                        <div class="flex justify-between">
                            <div class="w-full space-y-1">
                                <div class="flex items-center ">
                                    <Icon type="candidates" 		 class="shrink-0" color="#637381" :width="24" :height="24" />
                                    <div class="ml-2 text-sm">{{ post?.content?.attendees?.length }} {{ $t('person going') }}</div>
                                </div>
                                <div class="relative flex items-center group">
                                    <Icon type="location" 		 class="shrink-0" color="#637381" :width="24" :height="24" />
                                    <div class="ml-2 text-sm">   {{ post?.content?.location?.substring(0, 25) || '—' }}
													<div class="absolute z-10 invisible max-w-full px-2 py-1 text-xs font-bold text-center break-words transition-all rounded-md shadow opacity-0 group-hover:visible group-hover:opacity-100 left-8 -top-8 bg-grey-200 text-primary">       {{ post?.content?.location }} </div></div>
                                </div>
                            </div>
                            <div class="w-full space-y-1">
                                <div class="flex items-center">
                                    <Icon type="start" 		 class="shrink-0" color="#637381" :width="24" :height="24" />
                                    <div class="ml-2 text-sm">{{ formattedDate(post?.content?.start_time , 'D.MM.yyyy')
                                        }}
                                    </div>
                                </div>
                                <div class="flex items-center">
                                    <Icon type="w-full" 		 class="shrink-0" color="#637381" :width="24" :height="24" />
                                    <div class="ml-2 text-sm">{{ $t('Starts at') }}
                                        {{ formattedDate(post?.content?.start_time, 'LT') }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="flex flex-col gap-3 text-sm relative group whitespace-break-spaces text-[#637381]">
                            <template v-for="(part, index) in parsedContent" :key="index">
                                <template v-if="part.isLink">
                                    <LinkPreview class="inline-flex" :src="part.text" />
                                </template>
                                <template v-else>
                                    {{ part.text.substring(0, 100) }}...
												<div class="absolute z-10 invisible max-w-full px-2 py-1 text-xs font-bold text-center break-words transition-all rounded-md shadow opacity-0 group-hover:opacity-100 group-hover:visible left-8 -top-8 bg-grey-200 text-primary">       {{ part.text }} </div>
                                </template>
                            </template>
                        </p>
                        <div class="flex gap-2 justify-left md:justify-end">
                            <GenericButton v-if="superUser" @click="confirmDelete(post.id)" class="justify-center !py-1 max-w-16 md:max-w-40 w-full"
                                theme="system-contained" type="button" iconPosition="left">
                                <template #leftIcon>
                                    <span>
                                        <Icon type="delete" fill="#fff" :width="18" :height="18" />
                                    </span>
                                </template>
										  <span class="hidden md:inline-block">{{ $t('Delete') }}</span>
                            </GenericButton>
                            <GenericButton @click="shareThisEvent(post.id)" class="justify-center w-full max-w-16 md:max-w-40 !py-1"
                                theme="inherit-outlined" type="button" iconPosition="left">
                                <template #leftIcon>
                                    <span>
                                        <Icon type="share" color="#212B36" :width="18" :height="18" />
                                    </span>
                                </template>
<span class="hidden md:inline-block"> {{ $t('Share') }}</span>
                            </GenericButton>

                            <GenericButton v-if="invite && user !== null " @click="inviteUserToEventMethod" class="justify-center !py-1 max-w-40 w-full bg-[#d8daf3]"
                                style="color: #0516B2;" theme="primary-outlined" type="button" :text="$t('Invite')"
                                iconPosition="left">
                                <template #leftIcon>
                                    <span>
                                        <Icon type="envelop" color="#fff" :width="18" :height="18" />
                                    </span>
                                </template>
                            </GenericButton>
                            <GenericButton v-if=" toCheckUserIsAttend ?? toCheckIfTheUserIsEligibleToAttend "
                                class="justify-center w-full bg-[#0516B2] text-white !py-1" theme="primary-contained"
                                type="button" @click="attendThisEvent(post.id)" :text="$t('Attend')"
                                iconPosition="left">
                                <template #leftIcon>
                                    <span>
                                        <Icon type="star" color="#fffffff" :width="18" :height="18" />
                                    </span>
                                </template>
                            </GenericButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <EditPostModal :post="post" :modalOpen="editModal" @update:toggleModal="editPost" @update:data="handleData" />
    <InviteUserToEventModal :eventId="post?.content?.id" :postId="post.id"
        :inviteUserToEventBool="inviteUserToEventBool" @update:toggleModal="inviteUserToEventMethod" />
    <ovn-toast ref="toast"></ovn-toast>
</template>

<script>
import Avatar from '@/Components/Avatar.vue';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';
import GenericButton from '@/Components/GenericButton.vue';
import PostLike from '@/Components/PostLike.vue';
import AddTabFeature from '@/Pages/Property/Add/AddTabFeature.vue';
import { Link, router, usePage } from '@inertiajs/vue3';
import Axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ref } from 'vue';
import { Tippy } from 'vue-tippy';
import Comment from './Comment.vue';
import EditPostModal from './EditPostModal.vue';
import Icon from '@/Icons/jobs-icons.vue';
import InviteUserToEventModal from '@/Pages/Groups/Events/InviteUserToEventModal.vue';
import OvnToast from "@/Components/Toast.vue";
import jobsIcons from '@/Icons/jobs-icons.vue';
import LinkPreview from './LinkPreview.vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import YoutubeFrame from './YoutubeFrame.vue';
import VimeoFrame from "./VimeoFrame.vue";
import VideoPlayer from '@/Components/VideoPlayer.vue';
import { post } from 'jquery';
export default {

    props: {
        post: Object,
        comingFrom: String,
        superUser: {
            type: Boolean
        },
        invite:{
            type:Boolean,
            default:true
        },

    },
    components: {
        LinkPreview,
        PostLike,
        Comment,
        //   EmojiButton,
        Avatar,
        DropdownLink,
        Dropdown,
        GenericButton,
        AddTabFeature,
        EditPostModal,
        Icon,
        InviteUserToEventModal,
        OvnToast,
        // Carousel,
        Tippy,
        Link,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        YoutubeFrame,
        VimeoFrame,
        VideoPlayer
    },
    data() {
        return {
            selectedMedia: null,
            selectedEmoji: '',
            editModal: false,
            inviteUserToEventBool: false,
            showComments: false,
            path: import.meta.env.VITE_APP_URL,
            status: this.comingFrom,
            tooltipShow: false,
            currentSlide: 0, // Track the current slide index
            totalSlides: 0 ,// Track the total number of slides
            user : this.$page?.props?.auth?.user ,
        }
    },
    computed: {
        filePathsArray() {
            return this.post.media[0].file_path ? this.post.media[0].file_path.split('+') : [];
        },
        toCheckIfTheUserIsEligibleToAttend() {
            return this.$page?.props?.auth?.user?.id !== this.post?.user_id
        },
        toCheckUserIsAttend() {
            let check = this.post?.content?.attendees?.filter(attendee => attendee.user_id === this.$page?.props?.auth?.user?.id);
            return  (check && check.length > 0) ? false : true
        },
        parsedContent() {
            const content = (typeof this.post.content === 'string') ? this.post?.content : this.post?.content?.description;
            const urlRegex = /https?:\/\/[^\s]+/g;
            const result = [];

            let lastIndex = 0;
            let match;
            while ((match = urlRegex.exec(content)) !== null) {
                if (match.index > lastIndex) {
                    result.push({
                        isLink: false,
                        text: content.slice(lastIndex, match.index)
                    });
                }
                result.push({
                    isLink: true,
                    text: match[0]
                });
                lastIndex = match.index + match[0].length;
            }

            if (lastIndex < content?.length) {
                result.push({
                    isLink: false,
                    text: content.slice(lastIndex)
                });
            }
            return result;
        },
        formattedDate() {
            return function (date, formate) {
                return moment(date).format(formate ?? 'hh:mm A DD.MM.YYYY');
            };
        },
        displayName() {
            return this.post?.organization?.name || `${this.post.user.firstname} ${this.post.user.lastname}`;
        }
    },
    methods: {
        shareThisEvent(postId) {
            const urlForCopy =  route('agendas.events.attend', postId)
            if (this.isMobile()) {
                // Check if Web Share API is available
                if (navigator.share) {
                    navigator.share({
                        title: 'Check this out!',
                        url: urlForCopy
                    })
                        .then(() => {
                            this.$refs.toast.show('Shared successfully', 'success')
                        })
                        .catch(err => {
                            console.error('Error: ', err);
                        });
                } else {
                    console.error('Web Share API not supported.');
                }
            } else if (location.protocol === 'https:') {
                navigator.clipboard.writeText(urlForCopy)
                    .then(() => {
                        this.$refs.toast.show('Url Copied To Clipboard', 'success')
                    })
                    .catch(err => {
                        console.error('Error: ', err);
                    });
            } else {
                console.error('Not an HTTPS connection.');
            }
        },
        handleSlideStart({ slidingToIndex, currentSlideIndex, slidesCount }) {
            this.currentSlide = currentSlideIndex; // Update current slide index
            this.totalSlides = slidesCount; // Update total number of slides
        },
        shareThisPost(post_id) {
            const copiedUrl = import.meta.env.VITE_APP_URL + `/feed/posts/${post_id}/view/${this.status}`;
            if (this.isMobile()) {
                // Check if Web Share API is available
                if (navigator.share) {
                    navigator.share({
                        title: 'Check this out!',
                        url: copiedUrl
                    })
                        .then(() => {
                            this.$refs.toast.show('Shared successfully', 'success')
                        })
                        .catch(err => {
                            console.error('Error: ', err);
                        });
                } else {
                    console.error('Web Share API not supported.');
                }
            } else if (location.protocol === 'https:') {
                navigator.clipboard.writeText(copiedUrl)
                    .then(() => {
                        this.$refs.toast.show('Url Copied To Clipboard', 'success')
                    })
                    .catch(err => {
                        console.error('Error: ', err);
                    });
            } else {
                console.error('Not an HTTPS connection.');
            }
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        handleMediaClick(media) {
            this.selectedMedia = media;
        },
        mediaType(media) {
            if (media?.type === 'image') {
                return 'img';
            } else if (media?.type === 'video') {
                if(media?.embed){
                    if (media?.embed.includes('youtube') || media?.embed.includes('youtu.be')) {
                        return 'YoutubeFrame';
                    } else if (media?.embed.includes('vimeo')) {
                        return 'VimeoFrame';
                    }else{
                        return 'VideoPlayer';
                    }
                }
                return 'VideoPlayer';
            } else {
                return 'VideoPlayer';
            }
        },
        mediaTypeUrl(media, preview = false) {
            if(media?.url || media?.embed) {
                if (media?.type === 'image' && preview && media?.preview) {
                    return media.preview;
                } else if (media?.type === 'image' && media?.webp) {
                    return media.webp;
                }
                return media.url ?? media.embed;
            }else{
                return import.meta.env.VITE_APP_URL + '/assets/img/not-found.png';
            }
        },
        handleData(data) {
            return this.$emit('update:data', data);
        },
        submitComment() {
            if (!this.selectedEmoji.trim()) {
                return;
            }

            Axios.post('/add_comment', {
                comment: this.selectedEmoji,
                postId: this.post.id
            })
                .then(response => {
                    console.log('comment submitted:', response);
                    this.selectedEmoji = '';
                    const newComment = response.data.comments;

                    if (!this.post.comments) {
                        this.post.comments = [];
                    }

                    this.post.comments.push(newComment[0]);
                    // const emit = defineEmits(['update:comments']);
                    // emit('update:comments', post.comments);
                })
                .catch(error => {
                    console.error('Failed to submit comment:', error);
                });
        },

        handleEmojiSelected(emoji) {
            this.selectedEmoji += emoji;
        },
        editPost() {
            this.editModal = !this.editModal
        },
        inviteUserToEventMethod() {
            this.inviteUserToEventBool = !this.inviteUserToEventBool
        },

        confirmDelete(post_id) {
            Swal.fire({
                title: this.$t('Are you sure?'),
                text: this.$t('You will not be able to recover this post!'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: this.$t("Deleted!"),
                        text: this.$t("Your post has been deleted."),
                        icon: "success"
                    });
                    this.deleteItem(post_id);
                }
            });
        },

        confirmDeleteVideo(post_id) {
            Swal.fire({
                title: this.$t('Are you sure?'),
                text: this.$t('You will not be able to recover this video!'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: this.$t("Deleted!"),
                        text: this.$t("Your video has been deleted."),
                        icon: "success"
                    });
                    this.deleteItem(post_id , true);
                }
            });
        },

        deleteItem(post_id , isVideo = false) {
            const id = { id: post_id , video: isVideo };
            router.post(route('posts.delete'), id, {
                onSuccess: () => {
                    reloadData();
                }
            });
        },
        attendThisEvent(postId) {
            this.$inertia.get(route('agendas.events.attend', postId));
        },
        insertBrEvery30Chars(text) {
            let result = '';
            const chunkSize = 70;
            for (let i = 0; i < text.length; i += chunkSize) {
                result += text.substr(i, chunkSize);
                if (i + chunkSize < text.length) {
                    result += '<br>';
                }
            }
            return result;
        },
        enterTooltip() {
            this.tooltipShow = true;
        },
        leaveTooltip() {
            this.tooltipShow = false;
        }
    },
}
</script>
