<template>
    <div class="">
      <!-- <link href="https://vjs.zencdn.net/7.19.2/video-js.css" rel="stylesheet" /> -->
      <video
        ref="videoPlayer"
        class="video-js w-full h-full"
        controls
        preload="auto"
        width="600"
        height="300"
          @mouseover="playVideo"
      @mouseleave="pauseVideo"
      >
        <source :src="src" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </template>

  <script>
  import videojs from 'video.js';
  import 'video.js/dist/video-js.css';

  export default {
    props: {
      src: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.player = videojs(this.$refs.videoPlayer);
      this.player.ready(() => {
      this.player.play().catch((error) => {
        console.error("Error attempting to play:", error);
      });
    });
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    },
    methods: {
        playVideo() {
        this.player.play();
        },
        pauseVideo() {
        this.player.pause();
        },
    },
  };
  </script>

  <style scoped>
  .video-js .vjs-big-play-button {
    font-size: 3em;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 1;
    border: 2px solid #fff;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 50%;
    transition: background-color 0.4s;
  }

  .video-js .vjs-big-play-button:hover {
    background-color: rgba(255, 0, 0, 0.7);
  }

  .video-js .vjs-big-play-button:before {
    font-size: 30px;
    color: white;
  }
  </style>
